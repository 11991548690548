import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import renderHTML from 'react-render-html';
import MediaQuery from 'react-responsive';
import Swipeable from 'react-swipeable';
import Img from 'gatsby-image';
import _ from 'lodash';
import '../styles/components/HeroCarousel.scss';

class HeroCarousel extends Component {
  constructor() {
    super();

    this.state = {
      playVideo: 0,
      bounce: '',
      hover: false,
    };

    this.videoDOM = [];
  }

  onMouseEnterTitle = () => {
    this.setState({ hover: true });
  }

  onMouseLeaveTitle = () => {
    this.setState({ hover: false });
  }

  changeVideo = (index) => {
    this.setState({ playVideo: index });
  }

  touchMove = _.debounce((e, deltaX) => {
    const { latests } = this.props;
    let { playVideo, bounce } = this.state;

    if (deltaX > 90) playVideo += 1;
    else if (deltaX < -90) playVideo -= 1;
    else return;

    bounce = '';

    if (playVideo < 0) {
      bounce = '--forward';
      playVideo = 0;
    }

    if (playVideo >= latests.length) {
      bounce = '--backward';
      playVideo -= 1;
    }

    // Set State
    this.setState({ bounce });
    this.changeVideo(playVideo);

    // Remove state after playing animation
    this.removeBounce();
  }, 300)

  removeBounce() {
    const setBounce = () => {
      this.setState({ bounce: '' });
    };
    setTimeout(setBounce.bind(this), 500);
  }

  render() {
    const { latests } = this.props;
    const { playVideo, hover, bounce } = this.state;

    return (
      <div
        className="hero-carousel"
        onMouseEnter={this.onMouseEnterTitle}
        onMouseLeave={this.onMouseLeaveTitle}
        onTouchStart={this.onMouseEnterTitle}
        onTouchMove={this.onMouseEnterTitle}
        onTouchEnd={this.onMouseLeaveTitle}
      >
        <div className="hero-background-video">
          {/* eslint-disable jsx-a11y/media-has-caption */}
          {latests.map((item, index) => (
            <article
              className={(index === playVideo) ? 'is-active' : ''}
              key={item.title}
            >
              {/* eslint-disable jsx-a11y/anchor-is-valid */}
              <Link to={`/video/${item.slug}`}>
                <i className="play-video icon-play" />
              </Link>
              <MediaQuery minWidth={1200}>
                <span>
                  <iframe
                    title={item.title}
                    className={(index === playVideo) ? 'is-active' : ''}
                    src={`https://www.youtube.com/embed/${item.videoID}?autoplay=1&showinfo=0&controls=0&mute=1&loop=1&playlist=${item.videoID}`}
                  />
                </span>
              </MediaQuery>
              <Img
                resolutions={item.backgroundImage}
                className={`bg ${(index === playVideo) ? 'is-active' : ''}`}
                style={{ filter: hover && 'grayscale(0%)' }}
              />
            </article>
          ))}
        </div>
        <div className={`hero-carousel-item ${bounce} `}>
          <Swipeable
            onSwiping={this.touchMove}
          >
            <div className="container">
              <p className="hero-carousel-item_latest">
                Latest
              </p>
              <div className="hero-carousel-articles">
                {latests.map((item, index) => (
                  // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
                  <article
                    key={item.slug}
                    className={(index === playVideo) ? 'is-active' : ''}
                    onMouseOver={() => this.changeVideo(index)}
                    role="presentation"
                  >
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <h2>
                      <Link title={item.title} to={`/video/${item.slug}`}>
                        {renderHTML(item.title)}
                      </Link>
                    </h2>
                    <span>
                      {item.categories[0]}
                    </span>
                    <span className="time">
                      <i className="play-video icon-play" />
                      {item.time}
                    </span>
                  </article>
                ))}
              </div>
            </div>
          </Swipeable>
        </div>
      </div>
    );
  }
}

HeroCarousel.propTypes = {
  latests: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default HeroCarousel;
