import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import Leaderboard from '../components/Leaderboard';
import HeroCarousel from '../components/HeroCarousel';
import BoxRows from '../components/BoxRows';
import BoxScreen from '../components/BoxScreen';
import ShareMeta from '../components/ShareMeta';
import thumbnail from '../images/image-cover.jpg';

const HomePage = ({ pathContext }) => {
  const {
    latestPost,
    pickedPlaylists,
    pickedVideos,
    videos,
    meta,
  } = pathContext;

  return (
    <Layout>
      <div>
        <ShareMeta
          meta={{
            title: meta.title,
            description: meta.description,
            thumbnail,
          }}
        />
        <Leaderboard />
        <HeroCarousel latests={latestPost} />
        <BoxRows
          playlists={pickedPlaylists.map((playlist) => {
            // eslint-disable-next-line no-param-reassign
            playlist.description = '';
            return playlist;
          })}
        />
        <BoxScreen
          title="STAFF-<br>PICKS"
          articles={pickedVideos}
        />
        <BoxScreen
          title="ALL<br>VIDEOS"
          articles={videos}
          moreButton
        />
      </div>
    </Layout>
  );
};

HomePage.propTypes = {
  pathContext: PropTypes.shape(PropTypes.any.isRequired).isRequired,
};

export default HomePage;
